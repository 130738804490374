.borderRightCol {
  -webkit-box-shadow: 3px 0px 15px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 0px 15px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px 0px rgba(227, 227, 227, 0.75);
}

.livestreamIcons {
  position: relative;
  height: 7%;
  margin-top: 37px;
  left: 40%;
  width: 320px;
}
.iconic {
  -webkit-box-shadow: 0 0.2rem 0.5rem rgb(103 110 144 / 20%),
    0 0 0 0.1rem rgb(103 110 144 / 5%);
  -moz-box-shadow: 0 0.2rem 0.5rem rgb(103 110 144 / 20%),
    0 0 0 0.1rem rgb(103 110 144 / 5%);
  box-shadow: 0 0.2rem 0.5rem rgb(103 110 144 / 20%),
    0 0 0 0.1rem rgb(103 110 144 / 5%);
}

.active {
  color: white;
}

.menulink{
  font-family: "Biotif-Book";
  color: #92979c;
  font-size: 19x
}

.inactive {
  color: #b8b8b8;
}

.switchPosition {
  margin-top: 11px;
}

a {
  color: #C8C8C8		;
}

a:hover {
  color: #b8b8b8
}

.selected {
  background-color: #b8b8b8;
}

.cardIcon {
  background: -moz-linear-gradient(134deg, #ff9552 33%, #ba38ff);
  background: -ms-linear-gradient(134deg, #ff9552 33%, #ba38ff);
  background: -o-linear-gradient(134deg, #ff9552 33%, #ba38ff);
  background: -webkit-linear-gradient(134deg, #ff9552 33%, #ba38ff);
  background: linear-gradient(134deg, #ff9552 33%, #ba38ff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 22px;
  opacity: 0.7;
}

.cardIconText {
  background: -moz-linear-gradient(134deg, #ff9552 33%, #ba38ff);
  background: -ms-linear-gradient(134deg, #ff9552 33%, #ba38ff);
  background: -o-linear-gradient(134deg, #ff9552 33%, #ba38ff);
  background: -webkit-linear-gradient(134deg, #ff9552 33%, #ba38ff);
  background: linear-gradient(134deg, #ff9552 33%, #ba38ff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

#formGroupExampleInput::placeholder {
  font-family: "Sofia Pro";
}

.hoverBorder:hover{
  border-bottom:4px solid black

}

.basicInput::placeholder{
  color:"white";
  font-size: 14px;
  font-family: "Sofia Pro";
}
.shadowBox {
  -webkit-box-shadow: 0px 0px 15px 0px rgba(168, 168, 168, 1);
  -moz-box-shadow: 0px 0px 15px 0px rgba(168, 168, 168, 1);
  box-shadow: 0px 0px 3px 0px rgba(242, 242, 242, 1);
}

.cohostButton {
  -webkit-box-shadow: 3px 0px 36px -3px rgba(242, 242, 242, 1);
  -moz-box-shadow: 3px 0px 36px -3px rgba(242, 242, 242, 1);
  box-shadow: 3px 0px 36px -3px rgba(242, 242, 242, 1);
}

.growthBackground {
  background: -moz-linear-gradient(134deg, #ff9552 33%, #ba38ff);
  background: -ms-linear-gradient(134deg, #ff9552 33%, #ba38ff);
  background: -o-linear-gradient(134deg, #ff9552 33%, #ba38ff);
  background: -webkit-linear-gradient(134deg, #ff9552 33%, #ba38ff);
  background: linear-gradient(134deg, #ff9552 33%, #ba38ff);
}

/* mani css */

.session-container {
  width: 100% !important;
  height: 100% !important;
  position: relative;
}

.session-container .ot-layout > div {
  width: 100% !important;
  height: 100% !important;
}

#right {
  flex: 1;
}

#wrapper {
  display: flex;
}




.custom-select {
  font-size:14px
}

.form-control:focus{
  outline: none
}

.deposit::placeholder{
  font-family: "EinaSemiBold";
  color:#495057;
  font-size: 12px;
}

.depositMobile::placeholder{
  font-family: "EinaSemiBold";

  color:black;
  font-size: 13px;

}

.rightBox {
  box-shadow: -9px 0px 21px 0px rgba(236, 236, 236, 0.75);
-webkit-box-shadow: -9px 0px 21px 0px rgba(243, 243, 243, 0.75);
-moz-box-shadow: -9px 0px 21px 0px rgba(222,222,222,0.75);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.checkout::placeholder{
  font-family: "EinaSemiBold";
  letter-spacing: 1.6px;
  color:#808080;
  font-size: 16px;
}

.checkoutMobile::placeholder{
  font-family: "EinaSemiBold";

  color:black;
  font-size: 13px;

}

.container {
  background-color:white;
  height:75px;
  width:110vw;
  margin:0px 10px 0px 10px;
  overflow-x:scroll;
  padding:0px;
  white-space: nowrap;
  margin-left:0px;
  scrollbar-width: none;
  scrollbar-color: white;
  scrollbar-base-color: white;
  
 }



 .mobileContainer::-webkit-scrollbar {
  display: none;
  scrollbar-width: none;  /* Firefox */

}
 
 .thumbnail {
  background-color:blue;
  display:inline-block;
  height:100%;
  width:30%;
 }
 .thumbnail img {
  height:100%;
  width:100%;
 }

.carousel-container{
  width:700px
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  box-shadow: none;
  outline: none;
}


.zoom {
  border-radius: 9px;
  transition: transform .2s; /* Animation */


}

.zoom:hover {
  transform: scale(1.1);
  border-radius: 9px; /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}


