.backy {
  background-image: url("https://assets.website-files.com/5837424ae11409586f837994/61195e21f792d7065d2f56ad_noise.png"),
    linear-gradient(180deg, #fbe8de, #fbe8de);
}

.backy9 {
  background-image: url("https://assets.website-files.com/5837424ae11409586f837994/61195e21f792d7065d2f56ad_noise.png"),
    linear-gradient(180deg, #001529, #001529);
}

.backy3 {
  background-image: url("https://assets.website-files.com/5837424ae11409586f837994/61195e21f792d7065d2f56ad_noise.png"),
    linear-gradient(180deg, #e1eaf4, #e1eaf4);
}

.buttonSpecial {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 24px 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: #000;
  box-shadow: 6px 6px 0 0 rgb(0 0 0 / 20%);
  -webkit-transition: 0.2s;
  transition: 0.2s;
  font-family: Grifter, sans-serif;
  color: #fff;
  font-size: 17px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.5px;
  text-decoration: none;
}

.menuButton {
  font-family: "PlusJSMedium";
  font-size: 15px;
  margin-top: -44px;
  border-radius: 5px;
  display: inline-block;
  margin-left: 27px;
  margin-right: 65px;
}

.menuButtonMobile {
  font-family: "PlusJSMedium";
  font-size: 15px;
  border-radius: 5px;
  display: inline-block;
  top: 16px;
  position: absolute;
  right: 3%;
}
.menuButtonText {
  margin-top: 6px;
  text-align: "center";
  font-family: "PlusJSExtraBold";
  text-align: center;
  font-size: 15px;
}

.menuButtonDark {
  border: 1.3px solid white;
  color: white !important;
}
.menuButtonLight {
  border: 1.3px solid white;
  padding: 0px 12px;
  color: white !important;
  height: 36px;
}

.fade-in-out {
  opacity: 0;
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  transition: opacity 0.5s, visibility 0.5s 0s;
  z-index: 0 !important;
}
.visible {
  opacity: 1;
  visibility: visible;
  pointer-events: all !important;
  z-index: 9000 !important;
  display: block !important;
}

.topLevel {
  z-index: 80000;
}

.hideIcon {
  display: none;
}

@media only screen and (min-width: 600px) {
  .ais-SearchBox-input {
    height: 45px;
    width: 700px !important;
    margin-left: -50px !important;
    left: -40px;
    border-radius: 4;
    display: "inline-block";
    color: white;
    margin-top: -5px;
    background-color: #2b2b2b;
    font-family: "PlusJSSemiBold";
    padding-left: 17px;
    border: none;
    padding-right: 10px;
    font-size: 13px;
  }
}

@media only screen and (max-width: 600px) {
  .ais-SearchBox-input {
    height: 45px;
    width: 90vw !important;
    margin-left: 29px !important;
    left: 10px !important;
    border-radius: 4;
    display: "inline-block";
    color: white;
    margin-top: -5px;
    background-color: #2b2b2b;
    font-family: "PlusJSSemiBold";
    padding-left: 17px;
    border: none;
    padding-right: 10px;
    font-size: 13px;
  }
}
.ais-Hits {
  z-index: 80000 !important;
}
.ais-SearchBox-reset {
  display: none;
}

.ais-SearchBox-submit {
  display: none;
}

.backy70 {
  background-color: #2b2b2b;
  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
}

.backy80 {
  background-color: black;
}

.banner::-webkit-scrollbar {
  display: none;
}

.ribbon {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 50px;
  background: linear-gradient(to right, neon orange, pink, purple);
  color: white;
  z-index: 9000;
}

.ribbon::before,
.ribbon::after {
  content: "";
  position: absolute;
  width: 50%;
  height: 100%;
  background: inherit;
  animation: ribbon 4s ease-in-out infinite;
}

.ribbon::before {
  left: 0;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  transform-origin: left;
}

.ribbon::after {
  right: 0;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  transform-origin: right;
}

@keyframes ribbon {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(30deg);
  }
  100% {
    transform: rotate(0);
  }
}

#discord-button .icon {
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

#discord-button .icon svg {
  fill: white;
}

#discord-button a {
  color: white;
  font-weight: bold;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  padding: 10px 15px;
  background-color: #7289da;
  text-decoration: none;
}

#discord-button a:hover {
  background-color: #6a7fc9;
}
