.horizontal-scroll {
  display: flex;
  gap: 5px;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  width: 100vw;
}

body {
  overflow-x: hidden !important;
}

.horizontal-scroll::-webkit-scrollbar {
  display: none;
}

.miniRow::-webkit-scrollbar {
  display: none;
}

.horizontal-scroll__item {
  align-items: center;
  color: white;
  display: flex;
  flex: 0 0 220px;
  font-size: 40px;
  justify-content: center;
  scroll-snap-align: start;
}

.mini-horizontal-scroll__item {
  align-items: center;
  color: white;
  display: flex;
  flex: 0 0 90px;
  font-size: 40px;
  justify-content: center;
  scroll-snap-align: start;
}

.mobile-horizontal-scroll__item {
  align-items: center;
  color: white;
  display: flex;
  flex: 0 0 120px;
  font-size: 40px;
  justify-content: center;
  scroll-snap-align: start;
  padding-left: 80px;
}

.tinty {
  background-image: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.7)
  );
}
