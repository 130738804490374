@font-face {
  font-family: "Biotif-Bold";
  src: url("./Assets/Fonts/Biotif-Bold.ttf");
}
@font-face {
  font-family: "Biotif-Book";
  src: url("./Assets/Fonts/Biotif-Book.ttf");
}
@font-face {
  font-family: "Biotif-Light";
  src: url("./Assets/Fonts/Biotif-Light.ttf");
}
@font-face {
  font-family: "Righteous";
  src: url("./Assets/Fonts/Righteous-Regular.ttf");
}
@font-face {
  font-family: "Eina";
  src: url("./Assets/Fonts/Eina01-Regular.ttf");
}
@font-face {
  font-family: "EinaBold";
  src: url("./Assets/Fonts/Eina01-Bold.ttf");
}
@font-face {
  font-family: "EinaRegular";
  src: url("./Assets/Fonts/Eina01-Regular.ttf");
}
@font-face {
  font-family: "EinaSemiBold";
  src: url("./Assets/Fonts/Eina01-SemiBold.ttf");
}
@font-face {
  font-family: "MarkProBlack";
  src: url("./Assets/Fonts/MARKPROBLACK.OTF");
}
@font-face {
  font-family: "MarkProHeavy";
  src: url("./Assets/Fonts/MARKPROHEAVY.OTF");
}
@font-face {
  font-family: "MarkProMedium";
  src: url("./Assets/Fonts/MARKPROMEDIUM.OTF");
}
@font-face {
  font-family: "MarkProBold";
  src: url("./Assets/Fonts/MARKPROBOLD.OTF");
}
@font-face {
  font-family: "MarkProLight";
  src: url("./Assets/Fonts/MARKPROLIGHT.OTF");
}

@font-face {
  font-family: "Inter-Bold";
  src: url("./Assets/Fonts/Inter-Bold-slnt=0.ttf");
}

@font-face {
  font-family: "Inter-Black";
  src: url("./Assets/Fonts/Inter-Black-slnt=0.ttf");
}

@font-face {
  font-family: "Inter-Light";
  src: url("./Assets/Fonts/Inter-Light-slnt=0.ttf");
}

@font-face {
  font-family: "Inter-Medium";
  src: url("./Assets/Fonts/Inter-Medium-slnt=0.ttf");
}

@font-face {
  font-family: "Inter-Regular";
  src: url("./Assets/Fonts/Inter-Regular-slnt=0.ttf");
}
@font-face {
  font-family: "Inter-SemiBold";
  src: url("./Assets/Fonts/Inter-SemiBold-slnt=0.ttf");
}

@font-face {
  font-family: "GellixBold";
  src: url("./Assets/Fonts/GellixBold.otf");
}

@font-face {
  font-family: "LarsLight";
  src: url("./Assets/Fonts/LarsseitLight.ttf");
}

@font-face {
  font-family: "Yaro";
  src: url("./Assets/Fonts/YaroCut-Black.woff");
}

*:focus {
  outline: none !important;
}

input {
  outline: none !important;
}

input:focus,
input.form-control:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

html,
body,
#root,
.App {
  height: 100%;
  padding: 0px;
  margin: 0px;
}

.scrollmenu::-webkit-scrollbar {
  background-color: #eee;
  width: 10px;
  height: 7px;
}

.scrollmenu::-webkit-scrollbar-thumb {
  width: 10px;
  height: 7px;
  color: blue;
  background-color: #777;
}

.scrollmenu::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #eee;
}

::-webkit-scrollbar {
  background-color: #eee;
  width: 10px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  width: 10px;
  height: 7px;
  color: blue;
  background-color: #777;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #eee;
}

.scrollmenu a {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}

.scrollmenu a:hover {
  background-color: #777;
}

.PhoneInputInput {
  border: none;
}

.visible-scrollbar,
.invisible-scrollbar,
.mostly-customized-scrollbar {
  display: block;
  width: 0.5em;

  height: 2em;
}

/* Demonstrate a "mostly customized" scrollbar
 * (won't be visible otherwise if width/height is specified) */
.mostly-customized-scrollbar::-webkit-scrollbar {
  width: 0.5px;
  height: 8px;
  background-color: #696969; /* or add it to the track */
}

/* Add a thumb */
.mostly-customized-scrollbar::-webkit-scrollbar-thumb {
  background: #777;
}

@font-face {
  font-family: "Grifter";
  src: url("./Assets/Fonts/Grifter\ Sans\ Serif\ Font/grifterbold.otf");
}

@font-face {
  font-family: "PlusJSMedium";
  src: url("./Assets/Fonts/static/PlusJakartaSans-Medium.ttf");
}

@font-face {
  font-family: "PlusJSSemiBold";
  src: url("./Assets/Fonts/static/PlusJakartaSans-SemiBold.ttf");
}

@font-face {
  font-family: "PlusJSRegular";
  src: url("./Assets/Fonts/static/PlusJakartaSans-Regular.ttf");
}

@font-face {
  font-family: "PlusJSLight";
  src: url("./Assets/Fonts/static/PlusJakartaSans-Light.ttf");
}

@font-face {
  font-family: "PlusJSBold";
  src: url("./Assets/Fonts/static/PlusJakartaSans-Bold.ttf");
}

@font-face {
  font-family: "PlusJSExtraBold";
  src: url("./Assets/Fonts/static/PlusJakartaSans-ExtraBold.ttf");
}

@font-face {
  font-family: "TahomaRegular";
  src: url("./Assets/Fonts/TahomaRegular.ttf");
}

@font-face {
  font-family: "TahomaBold";
  src: url("./Assets/Fonts/TahomaBold.ttf");
}



.scrollmenu {
  margin: 4px, 4px;
  padding: 4px;
  background-color: transparent;
  width: 300px;
  overflow-x: auto;

  white-space: nowrap;
}

*:focus {
  outline: none !important;
}
